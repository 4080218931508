
import React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import Admin from './pages/Admin';
import OnboardingPage from './pages/Onboarding-page';
import SignUpPage from './pages/Sign-up-page';
import LoginPage from './pages/login-page'
import ForgotPassword from './pages/Forgot-Password';
import AccountDetails from './pages/AccountDetails';
import Overview from './pages/Overview';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import { HashRouter } from 'react-router-dom';
import { analytics } from './firebase';
const exampleName = "Your Name";
const exampleImageUrl = "https://deafassistant.com/londonmosque/images/logo-light.png";
const exampleQrCodeData = "Some QR Code Data";


const App: React.FC = () => {
  analytics.app.automaticDataCollectionEnabled = true
  return (
    <HashRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/Signup" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgetPassword" element={<ForgotPassword />} />

        {/* Protected routes inside AuthenticatedRoute */}
        <Route
          path="/"
          element={
            <AuthenticatedRoute>
            <Overview
    name={exampleName}
    imageUrl={exampleImageUrl}
    qrCodeData={exampleQrCodeData}
    
  />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthenticatedRoute>
              <AccountDetails/>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            
              <Admin />
            
          }
        />
        <Route
          path="/onboarding"
          element={
            <AuthenticatedRoute>
              <OnboardingPage/>
            </AuthenticatedRoute>
          }
        />
        {/* Add other routes as needed */}
      </Routes>
    </HashRouter>
  );
};

export default App;