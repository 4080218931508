// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCsl1BRFkZNJRnIjeyUAR1l0VRSmgccyzM",
  authDomain: "beawareconfcapportal.firebaseapp.com",
  projectId: "beawareconfcapportal",
  storageBucket: "beawareconfcapportal.appspot.com",
  messagingSenderId: "315608168836",
  appId: "1:315608168836:web:4c6fc6be8c3d7cf31745d1",
  measurementId: "G-0LVRKJGVDT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export { app, analytics, database };